import { Card, Table } from "react-bootstrap";
import useCallApi from "../../../hooks/useCallApi";
import moment from "moment";
import { colors } from '../../../theme/variables'
import Loading from '../../../templates/UI/Loading/Loading'
import { DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import callAPI from "../../../helpers/callAPI";
import serverColor from "../../../helpers/serverColor";
import Number from "../../../templates/Number/Number";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Summary = ({ dashboard }) => {
    const [init, setInit] = useState(true)

    const [data, setData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)

    const fetchData = async () => {
        setDataLoaded(false)
        let result = await callAPI(`mbStatus/monitoring/summary`, 'GET')
        if (result.success) {
            setData(result)
        }
        setDataLoaded(true)
    }

    useEffect(() => {
        if (init) {
            fetchData()
            setInit(false)
        }
        const interval = setInterval(() => {
            fetchData()
        }, 60000)
        return () => clearInterval(interval)
    }, [])

    const getStatus = (server) => {
        return data.details.applicationHealth.filter(item => item.name === server)[0].status
    }

    const getLastSuccessDate = (server) => {
        return data.details.applicationHealth.filter(item => item.name === server)[0].last_success_date
    }

    return (
        <Card className="mb-3">
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div>{dashboard ? "MB Status" : "Summary"}</div>
                    <div>{dashboard && <Link to="/system/mbStatus">Master Status Page</Link> }</div>
                </div>
            </Card.Header>
            <Card.Body>
                {dataLoaded && data ? (
                    <>
                        <div className="alert alert-success text-center max-width-100">
                            <h4 className="font-weight-bold">Action ID</h4>
                            <Number value={data.details.maxActionId} /> (<Number value={data.details.daysLeft} /> days left)
                        </div>
                        <div className="alert alert-warning p-2 text-center mt-2" style={{ backgroundColor: colors.yellowHighlight }}>
                            <h4 className="font-weight-bold">Clicks in past hour</h4>
                            Today: <Number value={data.details.clicksLastHourToday} /> Yesterday: <Number value={data.details.clicksLastHourYesterday} />
                        </div>
                        <Table className="mt-2">
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }} className=" border rounded p-2 text-center"><FontAwesomeIcon className={serverColor(getStatus('WS1'))} icon="circle" /> WS 1 ({getLastSuccessDate('WS1') ? moment(getLastSuccessDate('WS1')).utc().format('YYYY-MM-DD hh:mm:ss A') : 'Never'})</td>
                                    <td style={{ width: '50%' }} className=" border rounded p-2 text-center"><FontAwesomeIcon className={serverColor(getStatus('WS2'))} icon="circle" /> WS 2 ({getLastSuccessDate('WS2') ? moment(getLastSuccessDate('WS2')).utc().format('YYYY-MM-DD hh:mm:ss A') : 'Never'})</td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ width: '50%' }} className=" border rounded p-2 text-center"><FontAwesomeIcon className={serverColor(data.details.applicationHealth[2].status)} icon="circle" /> WS ADMIN ({moment(data.details.applicationHealth[2].last_success_date).utc().format('YYYY-MM-DD hh:mm:ss A')})</td>
                                </tr>
                            </tbody>
                        </Table>

                    </>
                ) : (
                    <>
                        <div className="alert alert-success text-center max-width-100">
                            <h4 className="font-weight-bold">Action ID</h4>
                            Loading...
                        </div>
                        <div className="alert alert-warning p-2 text-center mt-2" style={{ backgroundColor: colors.yellowHighlight }}>
                            <h4 className="font-weight-bold">Clicks in past hour</h4>
                            Loading...
                        </div>
                        <Table className="mt-2">
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }} className=" border rounded p-2 text-center">Loading...</td>
                                    <td style={{ width: '50%' }} className=" border rounded p-2 text-center">Loading...</td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ width: '50%' }} className=" border rounded p-2 text-center">Loading...</td>
                                </tr>
                            </tbody>
                        </Table>

                    </>                    
                )}
            </Card.Body>

        </Card >
    )
}

export { Summary }