import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'

import { Link } from 'react-router-dom/cjs/react-router-dom'
import EarningSummary from '../EarningSummary/EarningSummary'
import AffiliateInfo from './AffiliateInfo/AffiliateInformation'
import CampaignInfo from './CampaignInfo/CampaignInfo'
import MerchantCampaignPulls from './MerchantCampaignPulls/MerchantCampaignPulls'
import PreviousCampaignRequests from './PreviousCampaignRequests/PreviousCampaignRequests'
import RequestDetails from './RequestDetails/RequestDetails'
import RequestResultsForm from './RequestResultsForm/RequestResultsForm'
import RequestLog from './RequestLog/RequestLog'

const ReviewRequest = ({ requestId, affiliateId, campaignId, selectedOfferId, selectedAffiliateId, showSystemMessage, handleClose, handleCloseFlow, flow, type, requestStatus }) => {

    const [copied, setCopied] = useState('')

    return (
        <React.Fragment>
            {type !== 'page' && (
                <Modal.Header closeButton={handleClose}>
                    <Modal.Title>Campaign Request {requestStatus ? `: ${requestStatus}` : ''}</Modal.Title>
                </Modal.Header>
            )}
            <div className="card">
                {type === 'page' && <div className="card-header">Review Campaign Request</div>}
                {type !== 'page' && <div className='alert alert-secondary m-3'>
                    Page Link : <Link to={`/campaigns/approvalRequests/${requestId}?affiliateId=${affiliateId ? affiliateId : selectedAffiliateId}&campaignId=${campaignId ? campaignId : selectedOfferId}`}>{`${process.env.REACT_APP_PORTAL_URL}/campaigns/approvalRequests/${requestId}?affiliateId=${affiliateId ? affiliateId : selectedAffiliateId}&campaignId=${campaignId ? campaignId : selectedOfferId}`}</Link>
                    <span className='ml-3'>
                        <CopyToClipboard text={`${process.env.REACT_APP_PORTAL_URL}/campaigns/approvalRequests/${requestId}?affiliateId=${affiliateId ? affiliateId : selectedAffiliateId}&campaignId=${campaignId ? campaignId : selectedOfferId}`} onCopy={() => setCopied(true)}>
                            <button type="button" className="btn btn-outline-primary shadow-none" size="sm">
                                <FontAwesomeIcon icon="copy" />
                            </button>
                        </CopyToClipboard>
                    </span>
                </div>}
                <div className="row p-2">
                    <div className="col-lg-6" style={{ paddingRight: '6px' }}>
                        <AffiliateInfo affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} />
                        <RequestDetails key={requestId} requestId={requestId} showSystemMessage={showSystemMessage} />
                        <MerchantCampaignPulls affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} />
                        <PreviousCampaignRequests requestId={requestId} affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} />
                    </div>
                    <div className="col-lg-6" style={{ paddingLeft: '6px' }}>
                        <CampaignInfo offerId={campaignId ? campaignId : selectedOfferId} showSystemMessage={showSystemMessage} />
                        <RequestLog requestId={requestId} />
                        <RequestResultsForm key={requestId} requestId={requestId} showSystemMessage={showSystemMessage} handleClose={handleClose} handleCloseFlow={handleCloseFlow} flow={flow} />
                        <EarningSummary affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} statPeriod={'month'} target="affiliate" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReviewRequest
