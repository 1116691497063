import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'
import moment from 'moment'

const ReportDropdown = (props) => {
    const auth = useAuth()
    const showMarginReport = useProtectedContent(auth.permissions, 'report_margin')
    const showMarginReportNew = useProtectedContent(auth.permissions, 'report_margin_new')
    const showSotuReport = useProtectedContent(auth.permissions, 'sotu_report')
    const showBizdevPipelineReport = useProtectedContent(auth.permissions, 'bizdev_pipeline_report')
    const showCommissionReport = useProtectedContent(auth.permissions, 'commission_report')    

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right">
                Reports
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                {showMarginReport && (
                    <>
                        <Link className="dropdown-item" to={`/reports/margin?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`} onClick={toggle}>
                            Margin Report
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />                        
                    </>
                )}
                {showMarginReportNew &&
                    <>
                        <Link className="dropdown-item" to={`/reports/custom/margin?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`} onClick={toggle}>
                            Margin Report 2
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                }
                {showSotuReport && (
                    <>
                        <Link className="dropdown-item" to="/reports/sotu" onClick={toggle}>
                            State of the Union
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                {showBizdevPipelineReport && (
                    <>
                        <Link className="dropdown-item" to="/reports/bizdev_pipeline" onClick={toggle}>
                            Bizdev Pipeline Report
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                {showCommissionReport && (
                    <>
                        <Link className="dropdown-item" to="/commissionReport" onClick={toggle}>
                            Commission Report
                        </Link>
                    </>
                )}                
            </DropdownMenu>
        </Dropdown>
    )
}

export default ReportDropdown
