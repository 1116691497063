import { Link } from 'react-router-dom';
import { Button, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Play, Pause, Trash2 } from 'react-feather'
import { useContext, useState } from 'react';
import { TaskModal } from './TaskModal';
import ReportTable from '../../../templates/ReportTable/ReportTable'
import useCallApi from '../../../hooks/useCallApi';
import moment from 'moment';
import callAPI from '../../../helpers/callAPI';
import { SystemMessageContext } from "../../../context/SystemMessageContext";
import { useAuth, useProtectedPage } from '../../../context/AuthContext';

const SystemJobs = () => {

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'system_jobs')

    document.title = 'System Jobs'
    // const [tasks, setTasks] = useState([{
    //     isEdit: false,
    //     taskID: 1,
    //     name: "Task 1",
    //     start: "03/27/2024 1:10:10 PM",
    //     end: "03/27/2024 1:10:10 PM",
    //     intervalQuantity: "1",
    //     interval: "Week",
    //     lastRun: "03/27/2024 1:10:10 PM",
    //     nextRun: "4/03/2024 1:10:10 PM",
    //     group: "Default",
    // }]);
    const [taskVisible, setTaskVisible] = useState(false);
    const {showSystemMessage} = useContext(SystemMessageContext);
    const { data, dataLoaded, refetch } = useCallApi("systemJobs", "GET", {}, [taskVisible]);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [task, setTask] = useState({
        isEdit: false,
        taskID: '',
        name: '',
        start: '',
        end: '',
        intervalQuantity: '',
        interval: '',
        lastRun: '',
        nextRun: '',
        group: '',
        url: ''
    });

    const openTaskModal = (task, isEdit) => {
        setTask({ ...task, isEdit: isEdit });
        setTaskVisible(true);
    }

    const deleteTask = async (taskID) => {
        const result = await callAPI(`systemJob/${taskID}`, "DELETE", {}, {});
        if (result.success) {
            showSystemMessage("success", "Job deleted successfully.");
            refetch();
        } else {
            showSystemMessage("error", result.errors.message);
        }
        setConfirmDelete(false)
    }

    const pauseTask = async (taskID) => {
        const result = await callAPI(`systemJob/pause/${taskID}`, "PUT");
        if (result.success) {
            showSystemMessage("success", "Job paused successfully.");
            refetch();
        } else {
            showSystemMessage("error", result.errors.message);
        }
    }

    const resumeTask = async (taskID) => {
        const result = await callAPI(`systemJob/resume/${taskID}`, "PUT");
        if (result.success) {
            showSystemMessage("success", "Job resumed successfully.");
            refetch();
        } else {
            showSystemMessage("error", result.errors.message);
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: (tableValue) => {
                    return <>
                        {tableValue.row.original.status === "P" ?
                        <Button size="sm" variant="link" style={{ color: "blue" }} onClick={() => resumeTask(tableValue.row.original.job_id)} >
                            <Play size={14} color="#007bff" />
                        </Button>
                        : null}
                        {tableValue.row.original.status === "A" ?
                        <Button size="sm" variant="link" style={{ color: "orange" }} onClick={() => pauseTask(tableValue.row.original.job_id)} >
                            <Pause size={14} color="#FFA500" />
                        </Button>
                        : null}
                        <Button size="sm" variant="link" style={{ color: "orange" }} onClick={() => openTaskModal(tableValue.row.original, true)}>
                            <FontAwesomeIcon icon="pencil-alt" />
                        </Button>
                        {
                            confirmDelete === tableValue.row.original.job_id ?
                                <button className="btn btn-danger btn-sm"  onClick={() => deleteTask(tableValue.row.original.job_id)}>Confirm</button>
                            :
                                <Button size="sm" variant="link" style={{ color: "red" }} onClick={() => setConfirmDelete(tableValue.row.original.job_id)}>
                                    <Trash2 size={14} color="#FF0000" />
                                </Button>
                        }                        
                    </>
                },
            },
            {
                Header: 'Name',
                accessor: 'job_name',
                Cell: (tableValue) => {
                    return <Link to={`/system/jobHistory?jobName=${tableValue.row.original.job_name}`}>{tableValue.row.original.job_name}</Link>
                }
            },
            {
                Header: 'Start Date',
                accessor: 'start_date',
                Cell: ({ cell: { value } }) => {
                    return moment(value).utc().format('YYYY/MM/DD hh:mm:ss A')
                }
            },
            {
                Header: 'End Date',
                accessor: 'end_date',
                Cell: ({ cell: { value } }) => {
                    return moment(value).utc().format('YYYY/MM/DD hh:mm:ss A') !== 'Invalid date' ? moment(value).utc().format('YYYY/MM/DD hh:mm:ss A') : 'Indefinite'
                }
            },
            {
                Header: 'Interval',
                accessor: 'interval_type',
                Cell: (tableValue) => {
                    return `Every ${tableValue.row.original.interval_value} ${tableValue.row.original.interval_type}`
                }
            },
            {
                Header: 'Last Run',
                accessor: 'last_run_date',
                Cell: ({ cell: { value } }) => {
                    return moment(value).utc().format('YYYY/MM/DD hh:mm:ss A')
                }
            },
            {
                Header: 'Next Run',
                accessor: 'next_run_date',
                Cell: ({ cell: { value } }) => {
                    return moment(value).utc().format('YYYY/MM/DD hh:mm:ss A')
                }
            },
            {
                Header: 'Detailed Log',
                accessor: 'detailed_log',
                Cell: ({ cell: { value } }) => {
                    return value == 'Y' ? 'Yes' : 'No'
                }
            },
            {
                Header: 'Group',
                accessor: 'job_type',
                Cell: ({ cell: { value } }) => {
                    return value
                }
            },
        ];

        // const report = data.jobs.map((task) => {
        //     if(task.status === "A") {
        //         return task;
        //     } 
        // })
        const report = data.jobs.filter(task => task.status === "A"|| task.status === "P");
        console.log(report);
        return (
            <>
                <ReportTable data={report} columns={columns} />
            </>
        )
    }

    return (
        <>
            <TaskModal task={task} setTask={setTask} handleClose={() => setTaskVisible(false)} visible={taskVisible} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">System Jobs</h1>
            </div>
            <Card className="mb-3">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>Job List</span>
                    <div to="/system/jobs/add" className="btn btn-warning" onClick={() => openTaskModal(task, false)}>Create System Job</div>
                </Card.Header>
                <div className="table-responsive">
                   
                    {dataLoaded && data.success && getReportOutput()}
                </div>
            </Card>
        </>
    );
}

export { SystemJobs }